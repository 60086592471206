import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const sShapedDescent = keyframes`
  0% { 
    transform: translate(-200%, -120vh) rotate(-15deg);
  }
  20% {
    transform: translate(1000%, -90vh) rotate(20deg);
  }
  40% {
    transform: translate(-100%, -60vh) rotate(-10deg);
  }
  60% {
    transform: translate(50%, -30vh) rotate(5deg);
  }
  80% {
    transform: translate(-25%, -10vh) rotate(-3deg);
  }
  95% {
    transform: translate(5%, 5%) rotate(2deg);
  }
  100% { 
    transform: translate(0%, 0) rotate(0deg);
  }
`;

const fadeIn = keyframes`
  0% { opacity: 0; transform: scale(0.6) translateX(-50%); }
  100% { opacity: 0.3; transform: scale(1) translateX(-50%); }
`;

const UFO = styled.img`
  width: ${props => props.size}px;
  height: auto;
  position: absolute;
  top: calc(50% - ${props => props.size/2}px);
  left: calc(50% - ${props => props.size/2}px);
  animation: ${sShapedDescent} 6s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
`;

const Shadow = styled.div`
  width: ${props => props.size * 0.8}px;
  height: ${props => props.size * 0.1}px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  position: absolute;
  bottom: calc(50% - ${props => props.size/2}px - ${props => props.size * 0.05}px);
  left: 50%;
  transform-origin: center;
  animation: ${fadeIn} 1s cubic-bezier(0.39, 0.575, 0.565, 1) 4s forwards;
  opacity: 0;
`;

const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
`;



function UFOLandingLoader({ imageUrl, size, onAnimationComplete }) {
  const [animationEnded, setAnimationEnded] = useState(false);

  useEffect(() => {

    console.log('🛸 UFO animation started');
    const timer = setTimeout(() => {
      setAnimationEnded(true);
      if (onAnimationComplete) {
        onAnimationComplete();
      }
    }, 6500); // Total animation time: 6s descent + 0.5s buffer

    return () =>{ 
      console.log('🛸 Cleaning up UFO animation timer');
      clearTimeout(timer);}
  }, [onAnimationComplete]);

  return (
    <LoaderContainer style={{ opacity: animationEnded ? 0 : 1, transition: 'opacity 0.5s ease-out' }}>
      <div style={{ position: 'relative', width: size * 3, height: size * 2 }}>
        <Shadow size={size} />
        <UFO src={imageUrl} alt="UFO" size={size} />
      </div>
    </LoaderContainer>
  );
}

export default UFOLandingLoader;