import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import Papa from 'papaparse';
import { useLanguage } from './LanguageProvider';

const DataContext = createContext();

export const useData = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useData doit être utilisé dans un DataProvider');
  }
  return context;
};

export const DataProvider = ({ children }) => {
  const [data, setData] = useState({
    publications: [],
    team: [],
    research: [],
    tools: [],
    news: [],
    contact: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { language } = useLanguage();

  const baseUrl = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vRvv_lUiP6sK7A7QTxMI0eA3kllA9oj-D2Ee7BnCivHov_iKQ6p1CH96be-iMGi1_bDtMASdEC2Ibmi/pub?single=true&output=csv';

  const parseData = (csvData) => {
    return new Promise((resolve) => {
      Papa.parse(csvData, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          resolve(results.data);
        }
      });
    });
  };

  // Fetch publications only once
  useEffect(() => {
    const fetchPublications = async () => {
      try {
        console.log('Fetching publications...');
        const pubResponse = await axios.get(`${baseUrl}&gid=0`);
        const publicationsResult = await parseData(pubResponse.data);

        setData(prevData => ({
          ...prevData,
          publications: publicationsResult
        }));
      } catch (err) {
        console.error('Error fetching publications:', err);
        setError(err);
      }
    };

    fetchPublications();
  }, []); 

  useEffect(() => {
    const fetchOtherData = async () => {
      try {
        setLoading(true);
        console.log('Fetching language-dependent data...');

        const urls = {
          team: language === 'fr' ? `${baseUrl}&gid=1060799501` : `${baseUrl}&gid=1187176887`,
          research: language === 'fr' ? `${baseUrl}&gid=1203628962` : `${baseUrl}&gid=1137665444`,
          tools: language === 'fr' ? `${baseUrl}&gid=1187415274` : `${baseUrl}&gid=1307378646`,
          news: language === 'fr' ? `${baseUrl}&gid=1180969958` : `${baseUrl}&gid=1823112799`,
          contact: language === 'fr' ? `${baseUrl}&gid=634275759` : `${baseUrl}&gid=1211289998`
        };

        const [teamResponse, researchResponse, toolsResponse, newsResponse, contactResponse] = 
          await Promise.all([
            axios.get(urls.team),
            axios.get(urls.research),
            axios.get(urls.tools),
            axios.get(urls.news),
            axios.get(urls.contact)
          ]);

        const teamResult = await parseData(teamResponse.data);
        const researchResult = await parseData(researchResponse.data);
        const toolsResult = await parseData(toolsResponse.data);
        const newsResult = await parseData(newsResponse.data);
        const contactResult = await parseData(contactResponse.data);

        setData(prevData => ({
          ...prevData,
          team: teamResult,
          research: researchResult,
          tools: toolsResult,
          news: newsResult,
          contact: contactResult
        }));
      } catch (err) {
        console.error('Error fetching language-dependent data:', err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchOtherData();
  }, [language]); // Runs when language changes

  return (
    <DataContext.Provider value={{ data, loading, error }}>
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;