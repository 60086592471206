import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useLanguage } from '../components/LanguageProvider';

function Footer() {

  const {language} = useLanguage();
  return (
    <footer className="text-white py-3 mt-4" style={{ backgroundColor: '#0960fa', opacity: '0.6' }}>
      <Container>
        <Row className="align-items-center">


          <Col md={8} className="d-flex align-items-center gap-4 logo-container">
            <img 
              src={process.env.PUBLIC_URL +"/images/Udem_logo.png"}
              alt="Logo UdeM"
              className="h-8"
              style={{ height: '50px', width: 'auto', objectFit: 'contain' }}
            />
            <img 
              src={process.env.PUBLIC_URL +"/images/CRBLM_logo.png"}
              alt="Logo CRBLM"
              className="h-8"
              style={{ height: '32px', width: 'auto', objectFit: 'contain' }}
            />
            <img 
              src={process.env.PUBLIC_URL +"/images/CIRCA_logo.png"}
              alt="Logo CIRCA"
              className="h-8"
              style={{ height: '50px', width: 'auto', objectFit: 'contain' }}
            />

            <img 
              src={process.env.PUBLIC_URL +"/images/CRIUGM_logo.png"}
              alt="Logo CRIUGM"
              className="h-8"
              style={{ height: '50px', width: 'auto', objectFit: 'contain' }}
            />


          </Col>

          {/* Credits */}
          <Col md={4} className="text-md-end text-center mt-3 mt-md-0">
          
            <p className="mb-0 text-sm-white fs-6"> {language !== 'fr' ? 'Website by Declercq Kilian' : 'Site web par Declercq Kilian'}</p>
            <p className="mb-0 text-sm-white fs-6"> {language !== 'fr' ? 'Labo-Langage © 2024 - All rights reserved' : 'Labo-Langage © 2024 - Tous droits réservés'} </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;