import React, { useState, useEffect, lazy, Suspense } from 'react';
import { HashRouter  as Router, Route, Routes } from 'react-router-dom';


import 'bootstrap/dist/css/bootstrap.min.css';

import DataProvider from './components/DataLoader';
import { LanguageProvider } from './components/LanguageProvider';
import Header from './components/Header';
import Footer from './components/Footer';
import UFOLandingLoader from './components/UFOLandingLoader';
import CalendarEmbed from './components/CalendarEmbed';
import EarlyTitle from './components/EarlyTitle';
import './App.css';

const Home = lazy(() => import('./pages/Home'));
const Research = lazy(() => import('./pages/Research'));
const Publications = lazy(() => import('./pages/Publications'));
const Team = lazy(() => import('./pages/Team'));
const Tools = lazy(() => import('./pages/Tools'));
const Contact = lazy(() => import('./pages/Contact'));

function App() {
  const [loading, setLoading] = useState(true);
  const [showEarlyTitle, setShowEarlyTitle] = useState(false);
  const [calendarLoaded, setCalendarLoaded] = useState(false);

  useEffect(() => {
    // Show title after 4 seconds
    const showTitleTimer = setTimeout(() => setShowEarlyTitle(true), 4500);

    // Hide loader and title after 6.5 seconds (6s animation + 0.5s buffer)
    const hideLoaderTimer = setTimeout(() => {
      setLoading(false);
      setShowEarlyTitle(false);
    }, 6500);

    return () => {
      clearTimeout(showTitleTimer);
      clearTimeout(hideLoaderTimer);
    };
  }, []);

  const handleCalendarLoad = () => {
    setCalendarLoaded(true);
  };
  return (
    <Router>

      <LanguageProvider>
      <DataProvider>
        <div className="App min-h-screen flex flex-col">
          {loading && (
            <UFOLandingLoader 
              imageUrl={process.env.PUBLIC_URL +'/images/ovni.png'}
              size={120}
            />
          )}
          <EarlyTitle show={showEarlyTitle} />
          <div style={{ 
            opacity: loading ? 0 : 1, 
            transition: 'opacity 0.5s ease-in-out',
            position: loading ? 'absolute' : 'relative',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
          }}>
            <Header />
            <main className="flex-grow">
              <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/research" element={<Research />} />
                  <Route path="/publications" element={<Publications />} />
                  <Route path="/team" element={<Team />} />
                  <Route path="/tools" element={<Tools />} />
                  <Route path="/contact" element={<Contact />} />
                </Routes>
              </Suspense>
            </main>
            <Footer />
          </div>
          {!calendarLoaded && (
            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }}>
              <CalendarEmbed onLoad={handleCalendarLoad} />
            </div>
          )}
        </div>
      </DataProvider>

      </LanguageProvider>
    </Router>
  );
}

export default App;