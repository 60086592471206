import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import Ratio from 'react-bootstrap/Ratio';
import { useLanguage } from './LanguageProvider';  // Updated import

function CalendarEmbed({ onLoad }) {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const iframeRef = useRef(null);
  const { language } = useLanguage();  // Using useLanguage instead of useData

  // Memoize the calendar URL based on language
  const calendarUrl = useMemo(() => {
    const baseUrl = "https://open-web-calendar.hosted.quelltext.eu/calendar.html";
    const cssStyles = ".status-window%7Bdisplay%3A%20none%3B%7D%5Cn%5Cn.dhx_cal_navline%2C%20.dhx_scale_bar%2C%20.dhx_cal_container%2C%20.dhx_cal_header%2C%20.dhx_cal_scale_placeholder%2C%20.dhx_scale_holder%2C%20.dhx_scale_hour%20%7Bbackground-color%3A%20rgba(255%2C255%2C255%2C0)%3B%7D%0A.dhx_cal_tab.active%7Bbackground-color%3Argb(9%2C96%2C250)%7D%0A.event%2C%20.dhx_cal_tab.active%2C%20.dhx_cal_tab.active%3Ahover%20%7Bbackground-color%3A%20%230960fa%3B%7D%20.dhx_month_head%2C%20.dhx_cal_tab%2C%20.dhx_cal_today_button%20%7Bcolor%3A%20%230960fa%3B%7D%20.dhx_cal_tab%2C%20.dhx_cal_tab.active%20%7Bborder-color%3A%20%230960fa%3B%7D.dhx_cal_tab.active%20%7B%0A%20%20color%3A%20white%3B%0A%0A%7D";
    const calendarIcsUrl = "https%3A%2F%2Fcalendar.google.com%2Fcalendar%2Fical%2Flabolangage%2540gmail.com%2Fprivate-ab354d8118a39ac82b1f5a9bcdecfea2%2Fbasic.ics";
    
    return `${baseUrl}?css=${cssStyles}&language=${language === 'fr' ? 'fr' : 'en'}&title=&url=${calendarIcsUrl}`;
  }, [language]);

  // Reset loading state when language changes
  useEffect(() => {
    setIsLoading(true);
    setHasError(false);
  }, [language]);

  useEffect(() => {
    const iframe = iframeRef.current;

    const handleLoad = () => {
      setIsLoading(false);
      if (onLoad) onLoad();
    };

    const handleError = () => {
      setIsLoading(false);
      setHasError(true);
      if (onLoad) onLoad();
    };

    if (iframe) {
      iframe.addEventListener('load', handleLoad);
      iframe.addEventListener('error', handleError);
    }

    return () => {
      if (iframe) {
        iframe.removeEventListener('load', handleLoad);
        iframe.removeEventListener('error', handleError);
      }
    };
  }, [onLoad, language]);

  if (hasError) {
    return (
      <Container className="my-4">
        <div className="calendar-wrapper">
          <div className="text-center text-danger">
            {language === 'fr' 
              ? 'Échec du chargement du calendrier. Veuillez réessayer plus tard.'
              : 'Failed to load the calendar. Please try again later.'}
          </div>
        </div>
      </Container>
    );
  }

  return (
    <Container className="my-4">
      <div className="calendar-wrapper position-relative">
        {isLoading && (
          <div className="position-absolute top-50 start-50 translate-middle">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">
                {language === 'fr' ? 'Chargement...' : 'Loading...'}
              </span>
            </Spinner>
          </div>
        )}
        <Ratio aspectRatio="16x9" className="calendar-ratio">
          <iframe
            ref={iframeRef}
            id="open-web-calendar"
            title="Calendar"
            style={{
              width: '100%',
              height: '100%',
              border: '0',
              opacity: isLoading ? 0 : 1,
              transition: 'opacity 0.3s ease-in-out'
            }}
            src={calendarUrl}
            sandbox="allow-scripts allow-same-origin allow-top-navigation"
          />
        </Ratio>
      </div>
    </Container>
  );
}

export default CalendarEmbed;