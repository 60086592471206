import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import LanguageToggleButton from './LanguageButton';
import { useLanguage } from '../components/LanguageProvider';
import './Header.css';





function Header() {
  const location = useLocation();
  const {language} = useLanguage();
  

  const navbarStyle = {
    boxShadow: '0 2px 4px rgba(0,0,0,.1)',
    padding: '1rem 0',
    position: 'relative',
  };

  const navLinkStyle = {
    fontSize: '1.1rem',
    position: 'relative',
    padding: '0.5rem 1rem',
  };
  const languageToggleContainerStyle = {
    marginLeft: '1rem',
    borderLeft: '1px solid #dee2e6',  
    paddingLeft: '1rem', 
  };
  const colorBarStyle = {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '6px', 
    background: 'linear-gradient(to right, #0960fa 80%, #D5A00F 80%)',
  };

  return (
    <Navbar bg="light" expand="lg" style={navbarStyle}>
      <div style={colorBarStyle}></div>
      <Container>
        <Navbar.Brand as={Link} to="/" style={{ fontSize: '1.8rem' }}>Labo Langage</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link 
              as={Link} 
              to="/" 
              style={navLinkStyle} 
              className={`nav-item ${location.pathname === '/' ? 'active' : ''}`}
            >
              {language !== 'fr' ? 'Home' : 'Accueil'}
            </Nav.Link>
            <Nav.Link 
              as={Link} 
              to="/research" 
              style={navLinkStyle} 
              className={`nav-item ${location.pathname === '/research' ? 'active' : ''}`}
            >
              {language !== 'fr' ? 'Research' : 'Recherche'}
            </Nav.Link>
            <Nav.Link 
              as={Link} 
              to="/publications" 
              style={navLinkStyle} 
              className={`nav-item ${location.pathname === '/publications' ? 'active' : ''}`}
            >
              {language !== 'fr' ? 'Publications' : 'Publications'}
            </Nav.Link>
            <Nav.Link 
              as={Link} 
              to="/team" 
              style={navLinkStyle} 
              className={`nav-item ${location.pathname === '/team' ? 'active' : ''}`}
            >
              {language !== 'fr' ? 'Team members' : 'Collaborateurs'}
            </Nav.Link>

            <Nav.Link 
              as={Link} 
              to="/tools" 
              style={navLinkStyle} 
              className={`nav-item ${location.pathname === '/tools' ? 'active' : ''}`}
            >
              {language !== 'fr' ? 'Tools' : 'Outils'}
            </Nav.Link>

            <Nav.Link 
              as={Link} 
              to="/contact" 
              style={navLinkStyle} 
              className={`nav-item ${location.pathname === '/contact' ? 'active' : ''}`}
            >
              {language !== 'fr' ? 'Contact us' : 'Contact'}
            </Nav.Link>
          </Nav>
          <div style={languageToggleContainerStyle}>
          <LanguageToggleButton />
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
