import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { useLanguage } from '../components/LanguageProvider';

const TitleOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 1002;
  pointer-events: none;
`;

const TitleContainer = styled(Container)`
  margin-top: 8.2rem;
`;

const Title = styled.h1`
  font-family: "Chivo", sans-serif;
  font-weight: 900;
  font-size: 52px;
  line-height: 58px;
  margin-bottom: 1rem;
`;

const EarlyTitle = ({ show }) => {
  
  const {language} = useLanguage();
  
  return(

  
  <TitleOverlay style={{ opacity: show ? 1 : 0, transition: 'opacity 0.5s ease-in-out' }}>
    <TitleContainer>
      <Row>
        <Col>
          <Title>{language !== 'fr' ? "Welcome to Phaedra Royle's research laboratory" : 'Bienvenue sur le laboratoire de recherche de Phaedra Royle'}</Title>
        </Col>
      </Row>
    </TitleContainer>
  </TitleOverlay>
  

 
  );

};

export default EarlyTitle;