import React from 'react';
import { useLanguage } from './LanguageProvider';

const LanguageToggleButton = () => {
  const { language, toggleLanguage } = useLanguage();

  return (
    <button onClick={toggleLanguage} className="flag-toggle">
      <img
        src={language === 'fr' ? process.env.PUBLIC_URL + '/icons/canada.png' : process.env.PUBLIC_URL + '/icons/quebec.png'}
        alt={language === 'fr' ? 'Switch to English' : 'Passer en Français'}
        className="flag-icon"
      />
    </button>
  );
};

export default LanguageToggleButton;